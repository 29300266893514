import Home from "../src/component/Home/Home/Home";
import BlogDetail from "../src/component/Blog/BlogDetail";
import BlogList from "../src/component/Blog/BlogList";
import { Routes, Route } from "react-router-dom";
import React from "react";
import About from "./component/Home/About/About";
import Dashboard from "./component/Dashoboard/Dashboard/Dashboard";
import PrivateRoute from "./component/Login/PrivateRoute";
import NotFound from "./component/NotFound";
import Contact from "./component/Contact/Contact";

const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blogs" element={<BlogList />} />
        <Route path="/blogs/:id" element={<BlogDetail />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route
          path="/dashboard/*"
          element={
            <PrivateRoute redirectTo="/login">
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;
