import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert, Spinner } from 'react-bootstrap';
import './Contact.css'; // Import custom CSS file
import Footer from '../Home/Footer/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Contact = () => {
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ show: false, variant: '', message: '' });

    const sendEmail = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData(e.target);
        const data = {
            service_id: 'service_7jpj107',
            template_id: 'template_lewjszo',
            user_id: 'K9WDoIW0b1kXLFkmu',
            template_params: {
                name: formData.get('name'),
                phone: formData.get('phone'),
                email: formData.get('email'),
                company: formData.get('company'),
                subject: formData.get('subject'),
                message: formData.get('message'),
            }
        };

        try {
            const response = await fetch('https://api.emailjs.com/api/v1.0/email/send', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                setAlert({ show: true, variant: 'success', message: 'Email sent successfully!' });
                e.target.reset();
            } else {
                setAlert({ show: true, variant: 'danger', message: 'Failed to send email. Please try again later.' });
            }
        } catch (error) {
            setAlert({ show: true, variant: 'danger', message: 'Failed to send email. Please try again later.' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <main>
            <div className="contact-heading">
                <Container>
                    <h1 className='contact-heading-text'>Contact Us</h1>
                </Container>
            </div>
            <Container className="contact-form-heading my-5">
                <Row className="contact-form-row">
                    <Col lg={8} className="mb-4">
                        <p className="contact-intro-text">Contact us about anything related to our company or services. We'll do our best to get back to you as soon as possible.</p>
                    </Col>
                    <Col lg={4} className="contact-form-company-mail text-right">
                        <h5>VipraTech Solutions</h5>
                        <p>
                            <FontAwesomeIcon icon={faEnvelope} /> info@vipratechsolutions.com
                        </p>
                    </Col>
                </Row>
            </Container>
            <Container className="contact-form">
                {alert.show && <Alert variant={alert.variant} onClose={() => setAlert({ ...alert, show: false })}>
                    {alert.message}
                </Alert>}
                <Form onSubmit={sendEmail}>
                    <Row className="contact-form-row">
                        <Col lg={8}>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column lg={2} className="font-weight-bold">Name*</Form.Label>
                                <Col lg={10}>
                                    <Form.Control type="text" name="name" required autoFocus/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column lg={2} className="font-weight-bold">Phone Number</Form.Label>
                                <Col lg={10}>
                                    <Form.Control type="text" name="phone" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column lg={2} className="font-weight-bold">Email*</Form.Label>
                                <Col lg={10}>
                                    <Form.Control type="email" name="email" required />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column lg={2} className="font-weight-bold">Company</Form.Label>
                                <Col lg={10}>
                                    <Form.Control type="text" name="company" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column lg={2} className="font-weight-bold">Subject*</Form.Label>
                                <Col lg={10}>
                                    <Form.Control type="text" name="subject" required />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column lg={2} className="font-weight-bold">Message*</Form.Label>
                                <Col lg={10}>
                                    <Form.Control as="textarea" name="message" rows={7} required />
                                </Col>
                            </Form.Group>
                            <Button variant="primary" type="submit" disabled={loading} className="btn-submit">
                                {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Send'}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Container>
            <Footer />
        </main>
    );
}

export default Contact;
