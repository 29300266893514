import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Card, Container, Row, Col, Button } from 'react-bootstrap';
import './BlogList.css';
import Footer from '../Home/Footer/Footer';

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    axios.get('https://dev.to/api/articles?username=vipra_tech_solutions')
      .then(response => setBlogs(response.data))
      .catch(error => console.error('Error fetching data: ', error));
  }, []);

  return (
    <>
    <section>
    <Container className="blog-list mt-5">
      <Row>
        {blogs.map(blog => (
          <Col md={4} key={blog.id} className="mb-4">
            <Card>
              <Card.Img variant="top" src={blog.cover_image} />
              <Card.Body>
                <Card.Title>{blog.title}</Card.Title>
                <Card.Text>{blog.description}</Card.Text>
                <Link to={`/blogs/${blog.id}`}>
                  <Button variant="dark">Read More</Button>
                </Link>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
    </section>
    <section>
      <Footer/>
    </section>
    </>
  );
};

export default BlogList;
