import React from 'react';
import TechnicalExpertiseCard from './TechnicalExpertiseCard';

import pythonIcon from '../../../Assets/technologies/Python.svg';
import javaIcon from '../../../Assets/technologies/Java.svg';
import jsIcon from '../../../Assets/technologies/JavaScript.svg';

import angular from '../../../Assets/technologies/Angular.svg'
import react from '../../../Assets/technologies/React.svg'
import spring from '../../../Assets/technologies/Spring.svg'
import django from '../../../Assets/technologies/Django.svg'

import mongo from '../../../Assets/technologies/MongoDB.svg'
import postgres from '../../../Assets/technologies/PostgresSQL.svg'
import mysql from '../../../Assets/technologies/MySQL.svg'

import kafka from '../../../Assets/technologies/Apache Kafka.svg'


import aws from '../../../Assets/technologies/AWS.svg'
import docker from '../../../Assets/technologies/Docker.svg'

import './TechnicalExpertiseCard.css';
import {Col, Container, Row} from 'react-bootstrap';

const expertiseData = [
    {
        title: 'Programming Languages',
        description: 'Our team of experts is proficient in various programming languages, including Java, Python, and JavaScript. We develop custom solutions that meet your specific requirements.',
        icons: [javaIcon, pythonIcon, jsIcon],
        buttonText: 'Learn More'
    },
    {
        title: 'Frameworks',
        description: 'We specialize in using popular frameworks such as Spring Boot, Django, and ExpressJs to build robust and scalable applications. Our expertise ensures that your project is delivered on time and within budget.',
        icons: [spring, angular, react, django],
        buttonText: 'Learn More'
    },
    {
        title: 'Databases',
        description: 'Our team has experience working with various databases, including PostgreSQL, MongoDB, and DynamoDB. We design and optimize database structures to ensure fast and efficient data retrieval.',
        icons: [mongo, postgres, mysql],
        buttonText: 'Learn More'
    },
    {
        title: 'Messaging Systems',
        description: 'We have expertise in implementing messaging systems such as Apache Kafka and SQS to enable real-time communication and data processing. Our solutions help you stay ahead in today\'s fast-paced environment.',
        icons: [kafka],
        buttonText: 'Learn More'
    },
    {
        title: 'Tools and Platforms',
        description: 'We are proficient in using tools and platforms such as AWS, Docker, and others to deploy and manage applications efficiently. Our expertise ensures that your infrastructure is secure, scalable, and cost-effective.',
        icons: [aws, docker],
        buttonText: 'Learn More'
    }
];

const TechnicalExpertiseSection = () => {
    return (
        <section id="technical-expertise" className="section">
            <div className="technical-expertise-container text-center">
                <h2 className="sectionTitle">Technical Expertise</h2>
                <Container fluid>
                    <Row className="justify-content-center" xs={1} sm={1} md={2} lg={3} xl={4} xxl={5}>
                        {expertiseData.map((data, index) => (
                            <TechnicalExpertiseCard
                                key={index}
                                title={data.title}
                                description={data.description}
                                icons={data.icons}
                                buttonText={data.buttonText}
                            />
                        ))}
                    </Row>
                </Container>
            </div>
        </section>
    );
};

export default TechnicalExpertiseSection;
