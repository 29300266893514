import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import './BlogDetail.css';
import Footer from '../Home/Footer/Footer';

const BlogDetail = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    axios.get(`https://dev.to/api/articles/${id}`)
      .then(response => setBlog(response.data))
      .catch(error => console.error('Error fetching data: ', error));
  }, [id]);

  if (!blog) return <div>Loading...</div>;

  return (
    <>
    <section>
    <Container className="blog-detail mt-5">
      <h1>{blog.title}</h1>
      <img src={blog.cover_image} alt={blog.title} className="img-fluid blog-cover-image mb-4" />
      <div dangerouslySetInnerHTML={{ __html: blog.body_html }} />
    </Container>
    </section>
    <section>
      <Footer/>
    </section>
    </>
  );
};

export default BlogDetail;
