import React from 'react'
import './Hero.css';
import hero1 from "../../../Assets/hero-1.jpg";
import hero2 from "../../../Assets/hero-2.jpg";
import hero3 from "../../../Assets/hero-3.jpg";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { alpha, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typed from 'react-typed';
import { Carousel, Col, Container, Row } from 'react-bootstrap';


const Hero = () => {
  return (
    <section className='hero-block'>
      <Carousel data-bs-theme="dark">
        <Carousel.Item>
          <img src={hero1} />
          <Carousel.Caption>
            <h1>Transform Your Business With IT</h1>
            <h4>Get Expert IT Consulting from VipraTech Solution to streamline your operations and boost productivity.</h4>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={hero2} />
          <Carousel.Caption>
            <h1>Transform Your Business With IT</h1>
            <h4>Get Expert IT Consulting from VipraTech Solution to streamline your operations and boost productivity.</h4>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

    </section>
  );
};

export default Hero;
