import React from 'react';
import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faTools, faDatabase, faComments } from '@fortawesome/free-solid-svg-icons';
import Fade from 'react-reveal/Fade';
import './TechnicalExpertiseCard.css';

const TechnicalExpertiseCard = ({ title, description, icons, buttonText }) => {
    return (
        <Col>
            <Fade bottom duration={1800} distance='40px'>
                <div className="technical-card">
                    <div className="icon-container">
                        {icons.map((icon, index) => (
                            <img key={index} src={icon} alt={`Icon ${index}`} className="icon" />
                        ))}
                    </div>
                    <div className="text-container">
                        <h4>{title}</h4>
                        <p>{description}</p>
                        {/* <a href="#" className="knowMoreButton">{buttonText}</a> */}
                    </div>
                </div>
            </Fade>
        </Col>
    );
};

export default TechnicalExpertiseCard;
