import React from 'react';
import { Link } from "react-router-dom";
import './Navbar.css';
import { Container, Nav, Navbar } from 'react-bootstrap';
import brandIcon from "../../../Assets/VipraTech Solutions_transparent.png";

const NavBar = () => {

    const scrollTop = () => window['scrollTo']({ top: 0, behavior: 'smooth' });
    return (
        <Navbar expand="lg" bg="dark" data-bs-theme="dark" sticky={"top"}>
            <Container>
                <Navbar.Brand as={Link} to="/" onClick={scrollTop} className="navBrn">
                    <img src={ brandIcon } alt='' className="navIcon" />
                    <span className="navText">VipraTech <span className="navHighlight">Solutions</span></span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto mainNav" activeKey="/home">
                        <Nav.Item>
                            <Nav.Link href="/#services" className="nav-link">Services</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="/#testimonial" className="nav-link">Who We Are?</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="/blogs" className="nav-link">Blogs</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link to="/contact">
                                <button className="loginBtn">Contact Us</button>
                            </Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavBar;