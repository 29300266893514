import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons'
import FooterCol from './FooterCol';
import './Footer.css';
import '../../../App.css';
import { devOpsServices, ourServices, softwareDevServices, footerInfo } from '../../FooterData';
import FooterInfo from './FooterInfo';
import { Link } from 'react-router-dom';
import { scrollUP } from '../../Shared/ScrollTop/ScrollTop';


const Footer = () => {
    return (
        <section className='row footer'>
            <Row className="col-md-11 mx-auto">
                <Row className="align-items-center footerInfo">
                <Col md={6} className="fAboutUs">
                    {/* <h5>ABOUT US</h5>
                    <span className="animate-border"></span> */}
                    {
                        footerInfo.map(data => <FooterInfo data={data} key={data.id}/>)
                    }
                    <p className="aboutUsDes">VipraTech Solutions provides cutting-edge IT solutions, empowering enterprises to create exceptional digital experiences. We support businesses in achieving digital excellence and realizing their full potential.</p>
                    <ul className="socialIcons">
                        <li>
                            <Link onClick={scrollUP} to="https://www.linkedin.com/company/vipratech">
                                <FontAwesomeIcon icon={faLinkedinIn}/>
                            </Link>
                        </li>
                    </ul>
                </Col>
                    
                    <Col md={4} className="fAboutUs">
                    
                        <h2>Transforming Ideas into <br/>Technology Solutions</h2> 
                    </Col>
                    <Col md={2}>
                        <button className="contactUsBtnFooter">Get In Touch</button>
                    </Col>
                </Row>
                {/* <Col md={6} lg={3} className="fAboutUs">
                    <h5>ABOUT US</h5>
                    <span className="animate-border"></span>
                    <p className="aboutUsDes">VipraTech Solutions provides cutting-edge IT solutions, empowering enterprises to create exceptional digital experiences. We support businesses in achieving digital excellence and realizing their full potential.</p>
                    <ul className="socialIcons">
                        <li>
                            <Link onClick={scrollUP} to="/">
                                <FontAwesomeIcon icon={faLinkedinIn}/>
                            </Link>
                        </li>
                    </ul>
                </Col> */}
                {/* <FooterCol key="2" menuItems={usefulLink} title="USEFUL LINK"/> */}
                {/* <FooterCol key="3" menuItems={ourServices} title="WHAT WE DO"/> */}
                {/* <FooterCol key="4" menuItems={softwareDevServices} title="SOFTWARE DEVELOPMENT SERVICES"/> */}
                {/* <FooterCol key="5" menuItems={devOpsServices} title="DevOps SERVICES"/> */}
            </Row>
            <p className="copyRight">Copyright &copy; 2021-2024 <span className="fHighlight">VipraTech Solutions</span>. All rights reserved.</p>
        </section>
    );
};

export default Footer;