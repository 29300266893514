import React from 'react';
import { Link } from 'react-router-dom';
import './Service.css'
import Fade from 'react-reveal/Fade';
import './Service.css';
import { useAppContext } from '../../../context';
import { blue } from '@mui/material/colors';
import { Col } from 'react-bootstrap';

const Service = ({ service }) => {
    const { state: { admin }, dispatch } = useAppContext()
    const { name, price, description, img } = service;

    const handleSelectedService = (service) => {
        dispatch({ type: 'SELECTED_SERVICE', payload: service })
    }

    return (
        <Col>
            <Fade bottom duration={1800} distance='40px'>
                <div className="service-card">
                    <div className="service-text-container">
                        <h4>{name}</h4>
                        <p>{description}</p>
                        {/* <button className='knowMoreButton'>Know More</button> */}
                    </div>
                </div>
            </Fade>
        </Col>
    );
};

export default Service;