import React from 'react';
import Service from './Service';
import { Col, Container, Row } from 'react-bootstrap';
import './Service.css';

const Services = () => {

    return (
        <section id="services" className="services">
            <Container fluid>
                <Row>
                    <div>
                        <h2 className="text-center sectionTitle">SERVICES</h2>
                    </div>
                </Row>
                <Row className="justify-content-center"  xs={1} sm={2} md={3} lg={4}>
                    <Col>
                        <Service key="softDevelopment" service={{
                            "name": "Software Development",
                            "description": "Our expert team specializes in developing custom software solutions tailored to your business needs. From concept to deployment, we ensure that your software meets the highest standards of quality and performance"
                        }} />
                    </Col>
                    <Col>
                        <Service key="technicalTraining" service={{
                            "name": "Technical Training",
                            "description": "Stay ahead in the ever-evolving tech landscape with our technical training programs. From beginner to advanced levels, we offer courses that empower your team with the latest skills and knowledge."
                        }} />
                    </Col>
                    <Col>
                        <Service key="devOps" service={{
                            "name": " DevOps Services",
                            "description": "Embrace agility and efficiency with our comprehensive DevOps services. We help streamline your development and operations, enabling faster delivery of software updates and improved collaboration across teams."
                        }} />
                    </Col>
                    <Col>
                        <Service key="itStaffAug" service={{
                            "name": "IT Staff Augmentation",
                            "description": "Scale your team quickly and cost-effectively with our IT staff augmentation services. Whether you need temporary support or long-term resources, we provide skilled professionals to fill the gaps in your team"
                        }} />
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Services;