import React from 'react';
import Footer from '../Footer/Footer';
import TechnicalExpertiseSection from '../techicalexpertise/TechnicalExpertiseSection';
import Services from '../Services/Services';
import Hero from "../Hero/Hero";

const Home = () => {
    return (
        <main>
            <Hero/>
            <Services/>
            <TechnicalExpertiseSection/>
            <Footer/>
        </main>
    );
};

export default Home;